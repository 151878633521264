import "./main.css";

const Peer = require("simple-peer");
const axios = require("axios");
const Pusher = require("pusher-js");

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token");

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const loading = document.getElementById("loading");
const errorBox = document.getElementById("error_box");
const infoBox = document.getElementById("info_box");
const play = document.getElementById("play");
const terms = document.getElementById("terms");
const videosBox = document.getElementById("videos_box");
const professionalVideo = document.getElementById("peerVideo");
const alreadyAccepted = urlParams.has("a");
const logoDental = document.getElementById("logoDental");
const logoDoctor = document.getElementById("logoDoctor");
const termsDental = document.getElementById("dentalTerms");
const termsDoctor = document.getElementById("doctorTerms");

let currentProject;
let termsAccepted = false;
let peer;
let presenceChannel = null;
let channel = null;
let stream = null;
let errors = [
  "ERR_WEBRTC_SUPPORT",
  "ERR_CREATE_OFFER",
  "ERR_CREATE_ANSWER",
  "ERR_SET_LOCAL_DESCRIPTION",
  "ERR_SET_REMOTE_DESCRIPTION",
  "ERR_ADD_ICE_CANDIDATE",
  "ERR_ICE_CONNECTION_FAILURE",
  "ERR_SIGNALING",
  "ERR_DATA_CHANNEL",
  "ERR_CONNECTION_FAILURE",
];

axios.defaults.baseURL = process.env.API_ENDPOINT;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

if(parseJwt(token).app && parseJwt(token).app != "app")
  axios.defaults.baseURL = `${axios.defaults.baseURL.replace("app", parseJwt(token).app)}`;

const checkCurrentProject = () => {
  var host = window.location.host.split(".");
  currentProject = host[1];
  var labels;

  switch (process.env.CURRENT_PROJECT) {
    case "doctor":
      labels = document.getElementsByClassName("label-dental");
      logoDental.classList.add("d-none");
      termsDental.classList.add("d-none");
      termsDoctor.classList.remove("d-none");

      break;

    case "dental":
      labels = document.getElementsByClassName("label-doctor");
      logoDoctor.classList.add("d-none");
      termsDoctor.classList.add("d-none");
      termsDental.classList.remove("d-none");
      break;

    default:
      labels = document.getElementsByClassName("label-dental");
      logoDental.classList.add("d-none");
      logoDoctor.classList.add("d-none");
      termsDental.classList.add("d-none");
      termsDoctor.classList.remove("d-none");
      break;
  }

  for (var i = 0; i < labels.length; i++) {
    labels[i].classList.add("d-none");
  }

  play.classList.remove("d-none");
};

checkCurrentProject();

const showHideLoading = (show) => {
  if (show) {
    loading.classList.remove("d-none");
    professionalVideo.classList.add("invisible");
  } else {
    loading.classList.add("d-none");
    professionalVideo.classList.remove("invisible");
  }
};

// Cria o client do Pusher, socket usado no projeto.
const pusherClient = new Pusher(process.env.PUSHER_KEY, {
  wsHost: process.env.PUSHER_HOST,
  wsPort: 80,
  forceTLS: true,
  disableStats: true,
  enabledTransports: ["ws", "flash"],
  disabledTransports: ["flash"],
  pong_timeout: 6000, //default = 30000
  unavailable_timeout: 2000, //default = 10000
  authEndpoint: axios.defaults.baseURL + "/teleconference/auth.json",
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
});

pusherClient.connection.bind("state_change", (states) => {
  // console.log("GLOBAL state_change", states);
  if (states.current === "failed") {
    pusherClient.disconnect();
    pusherClient.connect();
  }
});

window.pusherClient = pusherClient;

// Inicia uma teleconsulta
const startTeleconsultation = (stream) => {
  // Manda carregar a consulta
  loadConsultation(function (data) {
    // Se carregou com sucesso a consulta manda criar o canal de presença,
    // Para avisar ao médico que o paciente ja está na sala.
    startPresenceChannel(data);
    // Abre o canal de mensagem, onde irá enviar e receber o offer/answer
    startChannel(data, function () {
      // Com o canal aberto, inicializa o Pear.
      initializePeer(stream);
    });
  });
};

// Assina o canal de presença do socket
const startPresenceChannel = (data) => {
  if (!data) return;
  // Nome do Canal de presença.
  // presence: Obrigatório
  // clientKey: Precisa pedir para o backend retornar o client key, ele virá no data.
  // Id: Id da teleconsulta
  var channelName = "presence-" + data.client_key + "-" + data.id;

  // console.log(data, channelName);

  // Assina o canal com o nome descrito acima. No Dental, o médico também assina este mesmo canal.
  presenceChannel = pusherClient.subscribe(channelName);

  // Evento disparado quando o médico conecta
  // Se ao entrar o médico já está conectado, não vai disparar.
  presenceChannel.bind("pusher:member_added", function (member) {
    // console.log("Médico conectado", member);
    loading.classList.add("d-none");
    // channel.trigger("client-request-offer", {});
  });

  // Evento que dispara quando o médico sai da conexão.
  presenceChannel.bind("pusher:member_removed", function (member) {
    // console.log("Médico desconectado", member);
    showHideLoading(true);
    // Manda iniciar novamente o Peer.
    initializePeer(stream);
  });

  presenceChannel.bind_global((event, data) => {
    // console.log("PUSHER PRESENCE GLOBAL", event, data);
  });

  window.presenceChannel = presenceChannel;
};

// Assina o canal de mensagem do socket
const startChannel = (data, callback) => {
  // Nome do Canal de mensagem.
  // private: Obrigatório
  // clientKey: Precisa pedir para o backend retornar o client key, ele virá no data.
  // Id: Id da teleconsulta
  var channelName = "private-" + data.client_key + "-" + data.id;

  // Assina o canal com o nome descrito acima. No Dental, o médico também assina este mesmo canal.
  channel = pusherClient.subscribe(channelName);

  // Evento disparado quando o canal é assinado com sucesso.
  channel.bind("pusher:subscription_succeeded", function () {
    // console.log("pusher:subscription_succeeded");
    // Manda chamar o callback
    callback();
    setInterval(function () {
      // console.log("ping");
      channel.trigger("client-ping", {});
    }, 5000);
    // Dispara evento para o canal solicitando o Offer.
    channel.trigger("client-request-offer", {});
  });

  // Evento disparado quando o médico envia o Offer.
  channel.bind("client-offer", function (data) {
    // console.log("offer received");
    // Envia o offer para o peer
    if (data && data.offer) {
      offerReceived(data.offer);
    } else {
      channel.trigger("client-request-offer", {});
    }
  });

  channel.bind("client-ping", function () {
    channel.trigger("client-pong", {});
  });

  channel.bind_global((event, data) => {
    // console.log("PUSHER CHANNEL GLOBAL", event, data);
  });
};

window.addEventListener(
  "offline",
  function (e) {
    // console.log("GLOBAL offline");
    if (presenceChannel) {
      presenceChannel.unbind_all();
      presenceChannel.unsubscribe();
    }
    if (channel) {
      channel.unbind_all();
      channel.unsubscribe();
    }
    if (pusherClient && pusherClient.connection.state !== "connected") {
      pusherClient.disconnect();
    }
  },
  false
);

window.addEventListener(
  "online",
  function (e) {
    window.location.replace(`${window.location.href}&a=1`);
  },
  false
);

play.onclick = function () {
  // Carrega as mídias e inicializa a aplicação
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      // Se o paciente autorizar ouso da camera e/ou audio
      // Manda carregar o vídeo dele na tela.
      loadMyVideo(stream);
      // Manda inicializar a Teleconsulta
      startTeleconsultation(stream);
    })
    .catch((err) => document.write(err));
  termsAccepted = true;
  videosBox.classList.remove("d-none");
  play.classList.add("d-none");
  infoBox.classList.add("d-none");
  terms.classList.add("d-none");
  document.body.classList.remove("no-professional");
};

// Helper para carregar a consulta
const loadConsultation = (callback) => {
  axios
    .get(`/teleconference.json?t=${new Date().getTime()}`)
    .then(function (response) {
      // console.log(response);
      if (callback) {
        callback(response.data, response);
      }
    })
    .catch(function (error) {
      // console.log(error, "axios");
      errorBox.classList.remove("d-none");
      showHideLoading(false);
    });
};

// Cria o vídeo do médico
const createVideo = (stream) => {
  let peerVideo = document.querySelector("#peerVideo");
  peerVideo.srcObject = stream;
  peerVideo.play();
};

// Quando recebe os dados de conexão do médico
const offerReceived = (offer) => {
  peer.signal(offer);
};

// Envia os dados de conexão do paciente
const sendAswer = (data) => {
  // console.log("changeDATA", "ANSWER", data);
  channel.trigger("client-answer", { answer: data });
  showHideLoading(false);
};

// Inicializar o Peer
const initializePeer = (initialStream) => {
  // return;
  // console.log("initializePeer");
  if (peer) {
    // peer.destroy();
  }

  stream = initialStream;

  peer = new Peer({
    initiator: false,
    stream: initialStream,
    trickle: false,
    offerConstraints: {
      offerToReceiveAudio: true,
      offerToReceiveVideo: true,
    },
    config: {
      iceServers: [
        { urls: "stun:stun.l.google.com:19302" },
        { urls: "stun:global.stun.twilio.com:3478" },
      ],
    },
  });

  peer.on("stream", function (stream) {
    createVideo(stream);
  });

  peer.on("signal", (data) => {
    sendAswer(data);
  });

  peer.on("error", function (error) {
    var error = errors.indexOf(arguments[0].code);

    if (error >= 0) {
      // console.log("error", arguments[0].code);
      // console.log("changeDATA", "TWO");
      initializePeer(stream);
    }
  });
};

// Carrega o vídeo do paciente
const loadMyVideo = function (stream) {
  const myVideo = document.querySelector("#myVideo");
  myVideo.srcObject = stream;
  myVideo.play();
};

if (alreadyAccepted) {
  play.textContent =
    process.CURRENT_PROJECT === "doctor"
      ? "Voltar para Teleconsulta"
      : "Voltar para Telemonitoramento";
  infoBox.classList.remove("d-none");
  terms.classList.add("d-none");
} else {
  videosBox.classList.add("d-none");
  play.classList.remove("d-none");
  terms.classList.remove("d-none");
  infoBox.classList.add("d-none");
  document.body.classList.add("no-professional");
}
